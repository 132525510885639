/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    em: "em",
    hr: "hr",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Bell states"), " are quantum states of ", React.createElement(_components.strong, null, "two"), " qubits that represent simple examples of quantum entanglement. When one of the two qubits is measured, it takes on a specific value, and the second qubit is forced to ", React.createElement(_components.em, null, "also"), " take on a specific value, as the entangled state collapses."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "(", React.createElement(_components.a, {
    href: "/codebook/03-circuits-many-qubits/02-all-tied-up/"
  }, "Learn more about entangled states in the PennyLane Codebook."), ")"), "\n", React.createElement(_components.p, null, "Bell states are also known as ", React.createElement(_components.strong, null, "EPR states"), " or ", React.createElement(_components.strong, null, "EPR pairs"), "."), "\n", React.createElement(_components.p, null, "There are four Bell states, which are as below."), "\n", React.createElement(_components.p, null, "$$\n|\\beta_{00}\\rangle = \\frac{|00\\rangle + |11\\rangle}{\\sqrt{2}} \\\\\n|\\beta_{01}\\rangle = \\frac{|01\\rangle + |10\\rangle}{\\sqrt{2}} \\\\\n|\\beta_{10}\\rangle = \\frac{|00\\rangle - |11\\rangle}{\\sqrt{2}} \\\\\n|\\beta_{11}\\rangle = \\frac{|01\\rangle - |10\\rangle}{\\sqrt{2}}\n$$"), "\n", React.createElement(_components.p, null, "For notations such as $|01\\rangle$ and $|11\\rangle$, the first digit in the brackets refers to the first qubit, and the second digit refers to the second qubit."), "\n", React.createElement(_components.p, null, "The Bell states can be produced by a very simple quantum circuit consisting of a Hadamard gate and a CNOT gate, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 51.26582278481012%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABJ0AAASdAHeZh94AAAAuUlEQVQoz5VSCwqFIBDs+F3D+3iMIJGKvmSQzmMWBJ/PjLcwrK467ow2ABBCQB5vtTjOc5MWvPeSY23bNvR9j2VZZI3RdR2stUWyH8LrugTneUoexxFaa8nrusq+tm2hlJLxvu+47/u7w5KUOD+OA9M0ycFY42UpSVVyyaN8zs6j/FfJJZIUDOdcnfBJ8hPmeRYrqpLT2+kXHyAHX3wYBhhjBCRmnRZUv00tuE4Sdvjk91+SSx89l/wBHPETRRYAmVIAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"A simple quantum circuit for producing Bell states.\"\n        title=\"A simple quantum circuit for producing Bell states.\"\n        src=\"/static/9a3d6b724f029f3950d51f092e7dffab/f058b/bell-states-circuit-diagram.png\"\n        srcset=\"/static/9a3d6b724f029f3950d51f092e7dffab/c26ae/bell-states-circuit-diagram.png 158w,\n/static/9a3d6b724f029f3950d51f092e7dffab/6bdcf/bell-states-circuit-diagram.png 315w,\n/static/9a3d6b724f029f3950d51f092e7dffab/f058b/bell-states-circuit-diagram.png 630w,\n/static/9a3d6b724f029f3950d51f092e7dffab/c67d4/bell-states-circuit-diagram.png 723w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "If qubit x starts with a state of $|0\\rangle$, the Hadamard gate puts it into a superposition of $|0\\rangle$ and $|1\\rangle$. The CNOT gate flips qubit y depending on the state of qubit x, but since qubit x is in a superposition of $|0\\rangle$ and $|1\\rangle$ this makes the final state of qubit y dependent on what the final state of qubit x turns out to be — thus the two qubits are part of an entangled state."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
